@media only screen and (max-width: 47.999em) {
	/* line 111, scss/80-themes/Saunders/60-components/media.scss */
	.c-media .tabs__item .icon {
		margin-right: 0;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .c-media .tabs__item .icon {
		margin-right: initial;
		margin-left: 0;
	}
}

@media only screen and (max-width: 47.999em) and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .c-media .tabs__item .icon {
		margin-right: 0;
	}
}

@media only screen and (max-width: 47.999em) {
	/* line 115, scss/80-themes/Saunders/60-components/media.scss */
	.c-media .tabs__item--selected .icon {
		margin-right: 5px;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .c-media .tabs__item--selected .icon {
		margin-right: initial;
		margin-left: 5px;
	}
}

@media only screen and (max-width: 47.999em) and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .c-media .tabs__item--selected .icon {
		margin-right: 0;
	}
}

/* line 120, scss/80-themes/Saunders/60-components/media.scss */
.c-media .flag--banner {
	right: 0;
	z-index: 3;
	top: 0;
	bottom: auto;
	margin-top: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-media .flag--banner {
	right: auto;
	left: 0;
}

/* line 134, scss/80-themes/Saunders/60-components/media.scss */
.c-media--inside {
	position: relative;
}

/* line 138, scss/80-themes/Saunders/60-components/media.scss */
.c-media--inside .media__photo-carousel-placeholder .carousel {
	margin-top: -5px;
}

/* line 141, scss/80-themes/Saunders/60-components/media.scss */
.c-media--inside .media__photo-carousel-placeholder .carousel .thumbs {
	background: rgb(var(--color-global-border));
	padding: 10px;
	box-sizing: border-box;
	border: 1px solid rgb(var(--color-global-border));
}

/* line 148, scss/80-themes/Saunders/60-components/media.scss */
.c-media--inside .media__photo-carousel-placeholder .carousel .media__photo.selected {
	border: 0;
}

/* line 151, scss/80-themes/Saunders/60-components/media.scss */
.c-media--inside .media__photo-carousel-placeholder .carousel .media__photo.selected .contents-wrapper {
	margin: 0;
}

/* line 157, scss/80-themes/Saunders/60-components/media.scss */
.c-media--inside .media__photo-carousel-placeholder .private-photos:empty {
	border: 0;
}

/* line 162, scss/80-themes/Saunders/60-components/media.scss */
.c-media--inside .tabs-container--inside {
	position: absolute;
	width: 100%;
}

/* line 166, scss/80-themes/Saunders/60-components/media.scss */
.c-media--inside .tabs-container--inside .enlarge-photo--visible {
	opacity: 1;
	position: relative;
	flex: 1 1 0;
	white-space: nowrap;
	font-size: 1em;
	background: rgba(0, 0, 0, 0.4);
	border: 0;
	margin-right: 1px;
}

/* line 183, scss/80-themes/Saunders/60-components/media.scss */
.c-media--inside .tabs-container--inside .enlarge-photo--visible:hover {
	cursor: pointer;
	background: rgb(var(--color-global-negative));
}

/* line 188, scss/80-themes/Saunders/60-components/media.scss */
.c-media--inside .tabs-container--inside .enlarge-photo--visible .icon {
	margin-right: 5px;
}

@media only screen and (max-width: 59.999em) {
	/* line 193, scss/80-themes/Saunders/60-components/media.scss */
	.c-media--inside .tabs-container--inside .enlarge-photo--visible .enlarge-photo--text {
		display: none;
	}
}

/* line 199, scss/80-themes/Saunders/60-components/media.scss */
.c-media--inside .tabs-container--inside .c-tabs {
	flex: 1 1 100%;
	display: flex;
	z-index: 2;
	border: 0;
	margin-bottom: 0;
}

/* line 207, scss/80-themes/Saunders/60-components/media.scss */
.c-media--inside .tabs-container--inside .c-tabs .tabs__item {
	flex: 1 1 0;
	text-align: center;
	white-space: nowrap;
	box-sizing: border-box;
	background: rgba(0, 0, 0, 0.4);
	color: rgb(var(--color-global-positive));
	transition: background .25s linear;
	margin-right: 1px;
	display: flex;
	align-items: center;
	justify-content: center;
}

/* line 230, scss/80-themes/Saunders/60-components/media.scss */
.c-media--inside .tabs-container--inside .c-tabs .tabs__item--selected {
	background: rgb(var(--color-global-negative));
	color: rgb(var(--color-global-positive));
}

/* line 234, scss/80-themes/Saunders/60-components/media.scss */
.c-media--inside .tabs-container--inside .c-tabs .tabs__item--selected .icon {
	color: rgb(var(--color-global-positive));
}

/* line 239, scss/80-themes/Saunders/60-components/media.scss */
.c-media--inside .tabs-container--inside .c-tabs .tabs__item-text {
	display: inline-block;
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* line 243, scss/80-themes/Saunders/60-components/media.scss */
.c-media--inside .tabs-container--inside .c-tabs .tabs__item .icon {
	color: rgb(var(--color-global-positive));
}

/* line 247, scss/80-themes/Saunders/60-components/media.scss */
.c-media--inside .tabs-container--inside .c-tabs .tabs__item:hover {
	cursor: pointer;
	background: rgb(var(--color-global-negative));
}

@media only screen and (max-width: 59.999em) {
	/* line 253, scss/80-themes/Saunders/60-components/media.scss */
	.c-media--inside .tabs-container--inside .c-tabs .tabs__item .tabs__item-text {
		display: none;
	}
}

/* line 260, scss/80-themes/Saunders/60-components/media.scss */
.c-media--inside .tabs-container--inside .o-listing-flag {
	position: relative;
	flex: 0;
	text-align: center;
	white-space: nowrap;
	box-sizing: border-box;
	line-height: normal;
	font-size: 1em !important;
	padding: 5px 20px;
}

/* line 273, scss/80-themes/Saunders/60-components/media.scss */
.c-media--inside .tabs-container--flex {
	display: flex;
}

/* line 277, scss/80-themes/Saunders/60-components/media.scss */
.c-media--inside .vod-player__header {
	transform: translateY(100%);
}

/* line 281, scss/80-themes/Saunders/60-components/media.scss */
.c-media--inside .vod-player--responsive .vod-player__header {
	bottom: 20px;
	top: auto;
}

/* line 290, scss/80-themes/Saunders/60-components/media.scss */
.mediamodule__fullscreen-wrapper iframe,
.c-media iframe {
	width: 100%;
	height: 100%;
}

/* line 296, scss/80-themes/Saunders/60-components/media.scss */
.media__photo-carousel-placeholder .thumbs .media__photo,
.media__floorplans-carousel-placeholder .thumbs .media__photo {
	margin-bottom: 5px;
	background-color: rgb(var(--color-global-positive));
}

/* line 302, scss/80-themes/Saunders/60-components/media.scss */
.enlarge-photo {
	z-index: 99999999;
	left: 0;
	position: absolute;
	top: 0;
	font-size: 1.3em;
	border-radius: 0;
	opacity: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .enlarge-photo {
	left: auto;
	right: 0;
}

@media only screen and (max-width: 29.999em) {
	/* line 302, scss/80-themes/Saunders/60-components/media.scss */
	.enlarge-photo {
		left: 0;
		opacity: 1;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .enlarge-photo {
		left: auto;
		right: 0;
	}
	/* line 316, scss/80-themes/Saunders/60-components/media.scss */
	.enlarge-photo .icon-text {
		display: none;
	}
}

@media only screen and (min-width: 48em) {
	/* line 322, scss/80-themes/Saunders/60-components/media.scss */
	.enlarge-photo .icon-search {
		display: none;
	}
}

/* line 333, scss/80-themes/Saunders/60-components/media.scss */
.tabs-content__wrapper:hover .enlarge-photo {
	opacity: 1;
	transition: opacity .25s;
}

/* line 338, scss/80-themes/Saunders/60-components/media.scss */
.media-module__wrapper:hover .enlarge-photo {
	opacity: 1;
	transition: opacity .25s;
}

/* line 345, scss/80-themes/Saunders/60-components/media.scss */
.carousel {
	position: relative;
	overflow: hidden;
}

/* line 350, scss/80-themes/Saunders/60-components/media.scss */
.thumbs {
	overflow: hidden;
}

/* line 354, scss/80-themes/Saunders/60-components/media.scss */
.media__smartimages-wrap {
	width: 10000%;
	margin: 10px 0 5px;
	display: inline-block;
}

/* line 362, scss/80-themes/Saunders/60-components/media.scss */
.media__photo.selected {
	box-sizing: border-box;
	border: 1px solid rgb(var(--color-global-primary));
}

/* line 369, scss/80-themes/Saunders/60-components/media.scss */
.media__photo.selected .contents-wrapper {
	margin: 5px;
}

/* line 374, scss/80-themes/Saunders/60-components/media.scss */
.media__photo.selected .contents-wrapper::before {
	z-index: 2;
	display: block;
	position: absolute;
	content: "";
	height: 100%;
	width: 100%;
	background-color: rgba(var(--color-global-primary), 0.5);
}

/* line 387, scss/80-themes/Saunders/60-components/media.scss */
.main-image .controls {
	position: absolute;
}

/* line 391, scss/80-themes/Saunders/60-components/media.scss */
.main-image .image-caption {
	z-index: 99;
	left: 0;
	display: flex;
	flex-wrap: nowrap;
	align-items: flex-end;
	position: absolute;
	bottom: 0;
	width: 100%;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .main-image .image-caption {
	left: auto;
	right: 0;
}

/* line 401, scss/80-themes/Saunders/60-components/media.scss */
.main-image .image-caption__property-details__wrapper {
	flex: 1;
	position: relative;
}

/* line 406, scss/80-themes/Saunders/60-components/media.scss */
.main-image .image-caption__image-nav__wrapper {
	flex: 0 0 auto;
	text-align: center;
}

/* line 412, scss/80-themes/Saunders/60-components/media.scss */
.main-image .image-caption__property-details__content {
	color: rgb(var(--color-global-positive));
	display: inline-block;
}

/* line 420, scss/80-themes/Saunders/60-components/media.scss */
.main-image .image-caption__property-details__content .image-caption__property-details__item {
	padding: 5px 10px;
	background-color: rgba(var(--color-global-negative), 0.4);
	display: inline-block;
	vertical-align: middle;
}

/* line 431, scss/80-themes/Saunders/60-components/media.scss */
.main-image .image-caption__property-details__content .image-caption__property-details__item:nth-of-type(2) {
	padding-left: 20px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .main-image .image-caption__property-details__content .image-caption__property-details__item:nth-of-type(2) {
	padding-left: initial;
	padding-right: 20px;
}

/* line 435, scss/80-themes/Saunders/60-components/media.scss */
.main-image .image-caption__property-details__content .image-caption__property-details__item--placeholder {
	height: 100%;
	vertical-align: middle;
	padding: 0;
	margin-left: 0;
	margin-right: 0;
}

/* line 443, scss/80-themes/Saunders/60-components/media.scss */
.main-image .image-caption__property-details__content .image-caption__property-details__item .icon {
	margin-right: 5px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .main-image .image-caption__property-details__content .image-caption__property-details__item .icon {
	margin-right: initial;
	margin-left: 5px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .main-image .image-caption__property-details__content .image-caption__property-details__item .icon {
		margin-right: 0;
	}
}

/* line 449, scss/80-themes/Saunders/60-components/media.scss */
.main-image .image-caption__image-nav__content {
	background-color: rgba(var(--color-global-negative), 0.4);
	color: rgb(var(--color-global-positive));
	padding: 5px 20px;
}

/* line 460, scss/80-themes/Saunders/60-components/media.scss */
.main-image,
.media__photo,
.aspect-ratio-sized-container {
	display: inline-block;
	width: 100%;
	position: relative;
}

/* line 467, scss/80-themes/Saunders/60-components/media.scss */
.main-image > .contents-wrapper,
.media__photo > .contents-wrapper,
.aspect-ratio-sized-container > .contents-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

/* line 474, scss/80-themes/Saunders/60-components/media.scss */
.main-image > .contents-wrapper:hover,
.media__photo > .contents-wrapper:hover,
.aspect-ratio-sized-container > .contents-wrapper:hover {
	cursor: pointer;
}

/* line 479, scss/80-themes/Saunders/60-components/media.scss */
.main-image > .placeholder-sizer,
.media__photo > .placeholder-sizer,
.aspect-ratio-sized-container > .placeholder-sizer {
	padding-top: 56.25%;
}

/* line 490, scss/80-themes/Saunders/60-components/media.scss */
.multimedia-photo__private-photo-remove-button {
	z-index: 800;
	left: 5px;
	position: absolute;
	padding: 5px;
	top: 5px;
	background-color: rgba(var(--color-global-negative), 0.4);
	color: rgb(var(--color-global-positive));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .multimedia-photo__private-photo-remove-button {
	left: auto;
	right: 5px;
}

/* line 500, scss/80-themes/Saunders/60-components/media.scss */
.map {
	height: 100%;
	width: auto;
}

/* line 505, scss/80-themes/Saunders/60-components/media.scss */
.map__directions {
	padding: 10px 0;
}

@media only screen and (max-width: 47.999em) {
	/* line 505, scss/80-themes/Saunders/60-components/media.scss */
	.map__directions {
		padding: 10px 20px 10px 20px;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .map__directions {
		padding: 10px 20px 10px 20px;
	}
}

/* line 514, scss/80-themes/Saunders/60-components/media.scss */
.private-photos {
	padding-top: 5px;
	border-top: 2px solid rgb(var(--color-global-border));
}

/* line 518, scss/80-themes/Saunders/60-components/media.scss */
.private-photos .icon {
	margin-right: 5px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .private-photos .icon {
	margin-right: initial;
	margin-left: 5px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .private-photos .icon {
		margin-right: 0;
	}
}

/* line 522, scss/80-themes/Saunders/60-components/media.scss */
.private-photos form {
	border: 2px solid rgb(var(--color-global-border));
	padding: 10px;
	margin: 10px 0;
}

/* line 528, scss/80-themes/Saunders/60-components/media.scss */
.private-photos:empty {
	padding-top: 0;
}

@media only screen and (max-width: 47.999em) {
	/* line 514, scss/80-themes/Saunders/60-components/media.scss */
	.private-photos {
		padding: 5px 20px 0 20px;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .private-photos {
		padding: 5px 20px 0 20px;
	}
}

/* line 537, scss/80-themes/Saunders/60-components/media.scss */
.tour {
	background-color: rgb(var(--color-global-widget2));
}

/* line 540, scss/80-themes/Saunders/60-components/media.scss */
.tour__icon {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: rgb(var(--color-global-helper));
	font-size: 45vw;
	position: absolute;
}

@media only screen and (min-width: 48em) and (max-width: 59.999em) {
	/* line 540, scss/80-themes/Saunders/60-components/media.scss */
	.tour__icon {
		font-size: 25vw;
	}
}

@media only screen and (max-width: 47.999em) {
	/* line 540, scss/80-themes/Saunders/60-components/media.scss */
	.tour__icon {
		font-size: 40vw;
	}
}

/* line 558, scss/80-themes/Saunders/60-components/media.scss */
.media__button {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 2;
	position: absolute;
	background-color: rgb(var(--color-global-primary));
	padding: 15px 20px;
	color: rgb(var(--color-global-body));
}

/* line 566, scss/80-themes/Saunders/60-components/media.scss */
.media__button:hover {
	background-color: rgb(var(--color-global-primary));
	color: rgb(var(--color-global-positive));
}

/* line 572, scss/80-themes/Saunders/60-components/media.scss */
.media__label {
	font-size: 14px;
	font-size: 1rem;
	margin-left: 5px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .media__label {
	margin-left: initial;
	margin-right: 5px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .media__label {
		margin-left: 0;
	}
}

/* line 578, scss/80-themes/Saunders/60-components/media.scss */
.controls__video-play {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 999;
	position: absolute;
	color: rgb(var(--color-global-positive));
	font-size: 4em;
	cursor: pointer;
	visibility: hidden;
	opacity: 0;
	transition: all .3s linear;
}

/* line 589, scss/80-themes/Saunders/60-components/media.scss */
.m-carousel:hover .controls__video-play {
	visibility: visible;
	opacity: 1;
}

/* line 598, scss/80-themes/Saunders/60-components/media.scss */
.mediamodule__fullscreen-media-wrapper {
	height: 100%;
	width: 100%;
	position: relative;
}

/* line 604, scss/80-themes/Saunders/60-components/media.scss */
.mediamodule__fullscreen-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgb(var(--color-global-negative));
	box-sizing: border-box;
}

/* line 613, scss/80-themes/Saunders/60-components/media.scss */
.mediamodule__fullscreen-wrapper .carousel__slide {
	display: inline-block;
	position: relative;
	cursor: pointer;
	vertical-align: top;
}

/* line 620, scss/80-themes/Saunders/60-components/media.scss */
.mediamodule__fullscreen-wrapper .o-imagenav--left-spaced {
	left: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .mediamodule__fullscreen-wrapper .o-imagenav--left-spaced {
	left: auto;
	right: 0;
}

/* line 624, scss/80-themes/Saunders/60-components/media.scss */
.mediamodule__fullscreen-wrapper .o-imagenav--right-spaced {
	right: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .mediamodule__fullscreen-wrapper .o-imagenav--right-spaced {
	right: auto;
	left: 0;
}

/* line 641, scss/80-themes/Saunders/60-components/media.scss */
.mediamodule__fullscreen-close,
.mediamodule__fullscreen-zoom-item,
.mediamodule__fullscreen-thumbnails-toggle {
	color: rgb(var(--color-global-positive));
}

/* line 646, scss/80-themes/Saunders/60-components/media.scss */
.mediamodule__fullscreen-close:hover,
.mediamodule__fullscreen-zoom-item:hover,
.mediamodule__fullscreen-thumbnails-toggle:hover {
	color: rgb(var(--color-global-positive));
}

/* line 651, scss/80-themes/Saunders/60-components/media.scss */
.mediamodule__fullscreen-close,
.mediamodule__fullscreen-zoom {
	z-index: 1;
	position: absolute;
	font-size: 1.5em;
	border-radius: 0;
}

/* line 659, scss/80-themes/Saunders/60-components/media.scss */
.mediamodule__fullscreen-close {
	top: 0;
	right: 0;
}

/* line 664, scss/80-themes/Saunders/60-components/media.scss */
.mediamodule__fullscreen-close-icon,
.mediamodule__fullscreen-zoom-item {
	padding: 8px;
}

/* line 669, scss/80-themes/Saunders/60-components/media.scss */
.mediamodule__fullscreen-close,
.mediamodule__fullscreen-thumbnails-toggle {
	background: rgba(var(--color-global-negative), 0.4);
}

/* line 674, scss/80-themes/Saunders/60-components/media.scss */
.mediamodule__fullscreen-close:hover,
.mediamodule__fullscreen-thumbnails-toggle:hover {
	background: rgba(var(--color-global-negative), 0.7);
}

/* line 681, scss/80-themes/Saunders/60-components/media.scss */
.mediamodule__fullscreen-zoom {
	right: 20px;
	bottom: 20px;
}

/* line 690, scss/80-themes/Saunders/60-components/media.scss */
.mediamodule__fullscreen-zoom-item {
	margin-top: 5px;
	cursor: pointer;
	background: rgba(var(--color-global-negative), 0.8);
}

/* line 698, scss/80-themes/Saunders/60-components/media.scss */
.mediamodule__fullscreen-zoom-item.icon {
	display: block;
}

/* line 702, scss/80-themes/Saunders/60-components/media.scss */
.mediamodule__fullscreen-zoom-item.is-disabled {
	cursor: default;
	color: rgb(var(--color-global-helper));
}

/* line 708, scss/80-themes/Saunders/60-components/media.scss */
.mediamodule__fullscreen-main-photo {
	height: 100%;
	position: relative;
	margin: 0 0;
}

/* line 723, scss/80-themes/Saunders/60-components/media.scss */
.mediamodule__fullscreen-main-photo .is-zoomed {
	transition: transform .4s ease-in-out;
}

/* line 728, scss/80-themes/Saunders/60-components/media.scss */
.mediamodule__fullscreen-thumbnails {
	min-height: 105px;
	white-space: nowrap;
	overflow: hidden;
	width: 100%;
	bottom: 0;
	box-sizing: border-box;
	padding: 0 20px;
	background: linear-gradient(transparent, rgba(var(--color-global-negative), 0.8));
}

/* line 741, scss/80-themes/Saunders/60-components/media.scss */
.is-zoom-enabled .mediamodule__fullscreen-thumbnails {
	padding-right: 70px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .is-zoom-enabled .mediamodule__fullscreen-thumbnails {
	padding-right: initial;
	padding-left: 70px;
}

/* line 745, scss/80-themes/Saunders/60-components/media.scss */
.mediamodule__fullscreen-thumbnails-arrows {
	cursor: pointer;
	text-align: center;
	position: absolute;
	left: 50%;
	transform: translate3d(-50%, -100%, 0);
}

/* line 756, scss/80-themes/Saunders/60-components/media.scss */
.mediamodule__fullscreen-thumbnails-toggle {
	padding: 5px 20px 0 20px;
	border-top-left-radius: 100vw;
	border-top-right-radius: 100vw;
	font-size: 2.5em;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .mediamodule__fullscreen-thumbnails-toggle {
	padding: 5px 20px 0 20px;
}

/* line 763, scss/80-themes/Saunders/60-components/media.scss */
.mediamodule__fullscreen-thumbnails .scroll-carousel__slider {
	padding-bottom: 10px;
}

/* line 767, scss/80-themes/Saunders/60-components/media.scss */
.mediamodule__fullscreen-thumbnails--animated {
	transition: transform 0.5s ease-in-out;
}

/* line 770, scss/80-themes/Saunders/60-components/media.scss */
.mediamodule__fullscreen-thumbnails--animated .scroll-carousel__slider {
	text-align: center;
}

/* line 774, scss/80-themes/Saunders/60-components/media.scss */
.mediamodule__fullscreen-thumbnails--animated.c-scroll-carousel {
	z-index: 49;
	position: absolute;
	overflow: visible;
}

/* line 781, scss/80-themes/Saunders/60-components/media.scss */
.mediamodule__fullscreen-thumbnails--hidden {
	transform: translateY(105px);
}

/* line 786, scss/80-themes/Saunders/60-components/media.scss */
.carousel__slide {
	padding-left: 10px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__slide {
	padding-left: initial;
	padding-right: 10px;
}

/* line 790, scss/80-themes/Saunders/60-components/media.scss */
.carousel__slide-spacer {
	box-sizing: border-box;
	height: 75px;
	display: block;
	border: 2px solid transparent;
	position: relative;
}

/* line 797, scss/80-themes/Saunders/60-components/media.scss */
.is-current .carousel__slide-spacer {
	border-color: rgb(var(--color-global-positive));
}

/* line 801, scss/80-themes/Saunders/60-components/media.scss */
.carousel__slide--icon-wrapper .carousel__slide-spacer {
	width: 75px;
	background: rgb(var(--color-global-helper));
}

/* line 808, scss/80-themes/Saunders/60-components/media.scss */
.carousel__slide-tab-name {
	color: rgb(var(--color-global-helper));
	font-size: 0.7em;
	bottom: 5px;
	position: absolute;
	white-space: nowrap;
	text-transform: uppercase;
}

/* line 816, scss/80-themes/Saunders/60-components/media.scss */
.is-current-tab .carousel__slide-tab-name {
	color: rgb(var(--color-global-positive));
}

/* line 821, scss/80-themes/Saunders/60-components/media.scss */
.carousel__slide--tab {
	padding-bottom: 20px;
}

/* line 824, scss/80-themes/Saunders/60-components/media.scss */
.carousel__slide--tab:not(:first-child) {
	margin-left: 15px;
	padding-left: 15px;
	border-left: 1px solid rgb(var(--color-global-helper));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__slide--tab:not(:first-child) {
	margin-left: initial;
	margin-right: 15px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .carousel__slide--tab:not(:first-child) {
		margin-left: 0;
	}
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__slide--tab:not(:first-child) {
	padding-left: initial;
	padding-right: 15px;
}

/* line 832, scss/80-themes/Saunders/60-components/media.scss */
.carousel__slide--placeholder .o-smartimage {
	opacity: 0.4;
}

/* line 836, scss/80-themes/Saunders/60-components/media.scss */
.carousel__slide--placeholder .carousel__slide-icon {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
	position: absolute;
	color: rgb(var(--color-global-positive));
}

/* line 846, scss/80-themes/Saunders/60-components/media.scss */
.media-module__wrapper .o-smartimage,
.mediamodule__fullscreen-thumbnails .o-smartimage {
	width: auto;
	border: 1px solid rgb(var(--color-global-border));
}

/* line 855, scss/80-themes/Saunders/60-components/media.scss */
.photo-caption {
	z-index: 3;
	left: 40px;
	position: absolute;
	bottom: 100px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .photo-caption {
	left: auto;
	right: 40px;
}

/* line 861, scss/80-themes/Saunders/60-components/media.scss */
.photo-caption-content {
	font-family: var(--font-family-headings);
	font-weight: 500;
	font-style: normal;
	font-display: swap;
	display: inline-block;
	color: rgb(var(--color-global-positive));
	text-shadow: 1px 1px 1px rgb(var(--color-global-negative));
	background: rgba(var(--color-global-negative), 0.7);
	padding: 0 10px;
}

@media only screen and (max-width: 47.999em) {
	/* line 861, scss/80-themes/Saunders/60-components/media.scss */
	.photo-caption-content {
		font-size: 1.1775em;
		line-height: 1.135;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 861, scss/80-themes/Saunders/60-components/media.scss */
	.photo-caption-content {
		font-size: 1.2855em;
		line-height: 1.5;
	}
}

@media only screen and (min-width: 80em) {
	/* line 861, scss/80-themes/Saunders/60-components/media.scss */
	.photo-caption-content {
		font-size: 1.5em;
		line-height: 1.5;
	}
}

/*# sourceMappingURL=../../../../true */